import { Box } from '@designSystem';
import Link from 'next/link';

import DixaLogotype from '../../designSystem/images/dixa-logotype.svg';

export type MainLayoutProps = {
  /** React children */
  children: string | React.ReactNode;
  /** children to render in the supporting pane, defaults to Dixa logo */
  asideChildren?: string | React.ReactNode;
};

export const MainLayout = ({
  children,
  asideChildren,
}: MainLayoutProps): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      css="min-height: 100vh; min-height: 100svh;"
    >
      <Box
        display={['none', 'none', 'flex']}
        alignItems="center"
        flex="1"
        css="height: 100vh; height: 100svh;"
        bg="primary"
        position="sticky"
        top={0}
        overflow="auto"
        alignSelf="start"
      >
        {!asideChildren && (
          <Box margin="auto" padding={12}>
            <Link href="/signin" passHref>
              <a>
                <DixaLogotype />
              </a>
            </Link>
          </Box>
        )}

        {asideChildren}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        width="full"
        maxWidth={['none', 'none', '50%']}
        flexShrink={0}
      >
        {children}
      </Box>
    </Box>
  );
};
