import { useRouter } from 'next/router';
import { ReactElement, useEffect } from 'react';
import { MainLayout } from 'src/components/mainLayout';

export function IndexPage(): JSX.Element | null {
  const router = useRouter();
  const query = router.query;

  useEffect(() => {
    if (!router.isReady) return;

    router.push({
      pathname: '/signin',
      query,
    });
  }, [router, query]);

  return null;
}

IndexPage.getLayout = (page: ReactElement): JSX.Element => {
  return <MainLayout>{page}</MainLayout>;
};

export default IndexPage;
